<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Detail Customer" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Proyek</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Proyek" v-model="data.proyek.nama" readonly/>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama" v-model="data.nama" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="No. Identitas" v-model="data.no_identitas" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <vs-input class="w-full" label-placeholder="Gender" :value="data.gender === 'L' ? 'Laki - Laki' : 'Perempuan'" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Tempat Lahir" v-model="data.tmp_lahir" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Tgl.Lahir</label>
            <vs-input class="w-full" v-model="data.tgl_lahir" readonly></vs-input>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp" v-model="data.telp" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" label-placeholder="HP" v-model="data.hp" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label-placeholder="E-mail" v-model="data.email" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Agama" v-model="data.agama" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Kelurahan" v-model="data.kelurahan" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Kota" v-model="data.kota" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Kode Pos" v-model="data.pos" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Tujuan Pembelian" v-model="data.tujuan_pembelian" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Domisili" v-model="data.domisili" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat" v-model="data.alamat" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Tipe *" v-model="data.tipe" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Sumber Dana" v-model="data.sumber_dana" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Nikah" v-model="data.status_nikah" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Nama Pasangan" v-model="data.nama_pasangan" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Tempat Tinggal" v-model="data.status_tmptgl" readonly/>
          </div>
        </div>

        <vs-divider> Pekerjaan </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Profesi" v-model="data.status_profesi" readonly/>
            <vs-input class="w-full mt-8" label-placeholder="Profesi" v-model="data.nama_profesi" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Status Profesi" v-model="data.status_profesi" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Jabatan" v-model="data.jabatan" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="NPWP" v-model="data.npwp" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat NPWP" v-model="data.alamat_npwp" readonly/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat Surat" v-model="data.alamat_surat" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="No. Rekening" v-model="data.no_rekening" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Nama Instansi" v-model="data.nama_instansi" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp. Instansi" v-model="data.telp_instansi" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-printer" label-placeholder="Fax Instansi" v-model="data.fax_instansi" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat Instansi" v-model="data.alamat_instansi" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="CP Instansi" v-model="data.cp_instansi" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Jenis Usaha" v-model="data.jenis_usaha" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Penghasilan Tetap" v-model="data.pengh_tetap" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Penghasilan Tambahan" v-model="data.pengh_tambah" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Penghasilan Bulanan" v-model="data.pengh_bulanan" readonly/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Tutup</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ProfesiRepository from '@/repositories/master/profesi-repository'
import _ from 'lodash'

export default {
  name: 'CustomerDetail',
  props: ['isActive', 'item'],
  mounted () {
    this.getAllProfesi()
  },
  data () {
    return {
      isModalProyekActive: false,
      isLoading: false,
      errors: null,
      data: {
        proyek: {},
        tipe_customer: 'master'
      },
      profesis: []
    }
  },

  watch: {
    isActive (value) {
      if (value === true) this.data = Object.assign({}, this.item)
    },
    'data.proyek' (value) {
      this.data.id_proyek = value.id
    },
    profesis (value) {
      this.data.id_proyek = value.nama
    }
  },
  methods: {
    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.profesis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['profesis'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
